import React, { useState, useCallback } from 'react';

const PassengerForm = React.memo(function PassengerForm({
  type,
  index,
  data,
  category,
  updatePassengerDetail,
  titleOptions,
  validationErrors
}) {
  return (
    <div style={{ marginBottom: '20px', padding: '15px', backgroundColor: '#f8f8f8', borderRadius: '8px' }}>
      <div style={{ marginBottom: '10px' }}>{type} {index + 1}</div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '15px' }}>
        <div>
          <select
            value={data.title || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'title', e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: validationErrors?.[category]?.[index]?.title ? '1px solid #dc3545' : '1px solid #ddd',
              width: '100%'
            }}
          >
            <option value="">Title</option>
            {titleOptions.map(title => (
              <option key={title} value={title}>{title}</option>
            ))}
          </select>
          {validationErrors?.[category]?.[index]?.title && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].title}
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder="First Name/Given Name"
            value={data.firstName || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'firstName', e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: validationErrors?.[category]?.[index]?.firstName ? '1px solid #dc3545' : '1px solid #ddd',
              width: '100%',
              textTransform: "uppercase"
            }}
          />
          {validationErrors?.[category]?.[index]?.firstName && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].firstName}
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder="Last Name/Surname"
            value={data.lastName || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'lastName', e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: validationErrors?.[category]?.[index]?.lastName ? '1px solid #dc3545' : '1px solid #ddd',
              width: '100%',
              textTransform: "uppercase"
            }}
          />
          {validationErrors?.[category]?.[index]?.lastName && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].lastName}
            </div>
          )}
        </div>
        {(type === 'Child' || type === 'Infant') && (
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              value={data.dob || ''}
              onChange={(e) => updatePassengerDetail(category, index, 'dob', e.target.value)}
              onFocus={(e) => (e.target.type = 'date')}
              onBlur={(e) => {
                if (!e.target.value) e.target.type = 'text';
              }}
              placeholder="Date of Birth"
              style={{
                padding: '8px',
                borderRadius: '4px',
                border: validationErrors?.[category]?.[index]?.dob ? '1px solid #dc3545' : '1px solid #ddd',
                width: '100%'
              }}
            />
            {validationErrors?.[category]?.[index]?.dob && (
              <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
                {validationErrors[category][index].dob}
              </div>
            )}
          </div>
        )}
        <div>
          <input
            type="text"
            placeholder="Nationality"
            value={data.nationality || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'nationality', e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: validationErrors?.[category]?.[index]?.nationality ? '1px solid #dc3545' : '1px solid #ddd',
              width: '100%',
              textTransform: "uppercase"
            }}
          />
          {validationErrors?.[category]?.[index]?.nationality && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].nationality}
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder="Passport Number"
            value={data.passportNumber || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'passportNumber', e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: validationErrors?.[category]?.[index]?.passportNumber ? '1px solid #dc3545' : '1px solid #ddd',
              width: '100%',
              textTransform: "uppercase"
            }}
          />
          {validationErrors?.[category]?.[index]?.passportNumber && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].passportNumber}
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder="Passport Issue Country"
            value={data.passportIssueCountry || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'passportIssueCountry', e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: validationErrors?.[category]?.[index]?.passportIssueCountry ? '1px solid #dc3545' : '1px solid #ddd',
              width: '100%',
              textTransform: "uppercase"
            }}
          />
          {validationErrors?.[category]?.[index]?.passportIssueCountry && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].passportIssueCountry}
            </div>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            value={data.passportExpiry || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'passportExpiry', e.target.value)}
            onFocus={(e) => (e.target.type = 'date')}   // Change to date on focus
            onBlur={(e) => {
              if (!e.target.value) e.target.type = 'text'; // Revert to text if empty
            }}
            placeholder="Passport Expiry Date"
            style={{ 
              padding: '8px', 
              borderRadius: '4px', 
              border: validationErrors?.[category]?.[index]?.passportExpiry ? '1px solid #dc3545' : '1px solid #ddd', 
              width: '100%' 
            }}
          />
          {validationErrors?.[category]?.[index]?.passportExpiry && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].passportExpiry}
            </div>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            value={data.passportIssue || ''}
            onChange={(e) => updatePassengerDetail(category, index, 'passportIssue', e.target.value)}
            onFocus={(e) => (e.target.type = 'date')}
            onBlur={(e) => {
              if (!e.target.value) e.target.type = 'text';
            }}
            placeholder="Passport Issue Date"
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: validationErrors?.[category]?.[index]?.passportIssue ? '1px solid #dc3545' : '1px solid #ddd',
              width: '100%'
            }}
          />
          {validationErrors?.[category]?.[index]?.passportIssue && (
            <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
              {validationErrors[category][index].passportIssue}
            </div>
          )}
        </div>
      </div>
      {type === 'Adult' && (
        <div style={{ marginTop: '10px' }}>

        </div>
      )}
    </div>
  );
});

const FlightInformation = ({ flightInfo }) => (
  <div style={{ marginBottom: '30px', backgroundColor: '#f0f8ff', padding: '20px', borderRadius: '8px', border: '1px solid #cce5ff' }}>
    <h3 style={{ marginBottom: '15px', color: '#0056b3' }}>Flight Information</h3>
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '15px' }}>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Airline:</span>
        <div style={{ fontWeight: 'bold' }}>{flightInfo.airline}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Flight Number:</span>
        <div style={{ fontWeight: 'bold' }}>{flightInfo.flightNumber || flightInfo.ticket_id}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Date:</span>
        <div>{flightInfo.date}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>From:</span>
        <div>{flightInfo.from}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>To:</span>
        <div>{flightInfo.to}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Departure:</span>
        <div>{flightInfo.departureTime}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Arrival:</span>
        <div>{flightInfo.arrivalTime}</div>
      </div>
    </div>
  </div>
);

const PricingSummary = ({ flightInfo }) => (
  <div style={{ marginBottom: '30px', backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', border: '1px solid #ddd' }}>
    <h3 style={{ marginBottom: '15px', color: '#333' }}>Price Summary</h3>
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '15px' }}>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Base Fare:</span>
        <div>${flightInfo.basePrice || 0}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Taxes & Fees:</span>
        <div>${flightInfo.taxes || 0}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Total Price:</span>
        <div style={{ fontWeight: 'bold', color: '#0056b3', fontSize: '1.1em' }}>${flightInfo.totalPrice || (flightInfo.basePrice ? flightInfo.basePrice + (flightInfo.taxes || 0) : 0)}</div>
      </div>
    </div>
  </div>
);

const PassengerSummary = ({ type, index, data, formatDate }) => (
  <div style={{ marginBottom: '15px', padding: '12px', backgroundColor: '#f8f8f8', borderRadius: '8px' }}>
    <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>{type} {index + 1}</div>
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Name:</span>
        <div>{data.title} {data.firstName} {data.lastName}</div>
      </div>
      {(type === 'Child' || type === 'Infant') && (
        <div>
          <span style={{ color: '#666', fontSize: '0.9em' }}>Date of Birth:</span>
          <div>{formatDate(data.dob)}</div>
        </div>
      )}
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Nationality:</span>
        <div>{data.nationality}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Passport:</span>
        <div>{data.passportNumber}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Issue Country:</span>
        <div>{data.passportIssueCountry}</div>
      </div>
      <div>
        <span style={{ color: '#666', fontSize: '0.9em' }}>Expiry Date:</span>
        <div>{formatDate(data.passportExpiry)}</div>
      </div>
    </div>
  </div>
);

const PassengerDetailsForm = ({ passengers, flightInfo, onSubmit, onBack, navigateToBooking }) => {
  const [currentStep, setCurrentStep] = useState('form'); // 'form' or 'preview'
  const [contactDetails, setContactDetails] = useState({
    countryCode: '+91',
    mobile: '',
    email: ''
  });

  const [validationErrors, setValidationErrors] = useState({
    contactDetails: {},
    adults: [],
    children: [],
    infants: []
  });

  const createInitialPassengerDetails = () => ({
    adults: Array.from({ length: passengers.adult }, () => ({
      title: '',
      firstName: '',
      lastName: '',
      frequentFlyerNumber: '',
      nationality: '',
      passportNumber: '',
      passportIssueCountry: '',
      passportExpiry: ''
    })),
    children: Array.from({ length: passengers.child }, () => ({
      title: '',
      firstName: '',
      lastName: '',
      dob: '',
      nationality: '',
      passportNumber: '',
      passportIssueCountry: '',
      passportExpiry: ''
    })),
    infants: Array.from({ length: passengers.infant }, () => ({
      title: '',
      firstName: '',
      lastName: '',
      dob: '',
      nationality: '',
      passportNumber: '',
      passportIssueCountry: '',
      passportExpiry: ''
    }))
  });

  const [submitting, setSubmitting] = useState(false);
  const [passengerDetails, setPassengerDetails] = useState(createInitialPassengerDetails());

  const updatePassengerDetail = useCallback((category, index, field, value) => {
    setPassengerDetails(prev => ({
      ...prev,
      [category]: prev[category].map((passenger, i) =>
        i === index ? { ...passenger, [field]: value } : passenger
      )
    }));

    // Clear validation error for this field when user updates it
    if (validationErrors[category]?.[index]?.[field]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        if (newErrors[category][index]) {
          newErrors[category][index] = { ...newErrors[category][index] };
          delete newErrors[category][index][field];
        }
        return newErrors;
      });
    }
  }, [validationErrors]);

  const updateContactDetail = useCallback((field, value) => {
    setContactDetails(prev => ({
      ...prev,
      [field]: value
    }));

    // Clear validation error for this field when user updates it
    if (validationErrors.contactDetails?.[field]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        if (newErrors.contactDetails) {
          newErrors.contactDetails = { ...newErrors.contactDetails };
          delete newErrors.contactDetails[field];
        }
        return newErrors;
      });
    }
  }, [validationErrors]);

  const titleOptions = ["Mr", "Mrs", "Ms"];
  const countryCodes = ["+91", "+966"];

  const validateForm = () => {
    const errors = {
      contactDetails: {},
      adults: Array(passengers.adult).fill({}).map(() => ({})),
      children: Array(passengers.child).fill({}).map(() => ({})),
      infants: Array(passengers.infant).fill({}).map(() => ({}))
    };

    let hasErrors = false;

    // Validate contact details
    if (!contactDetails.mobile.trim()) {
      errors.contactDetails.mobile = 'Mobile number is required';
      hasErrors = true;
    } else if (!/^\d{10}$/.test(contactDetails.mobile.trim())) {
      errors.contactDetails.mobile = 'Please enter a valid 10-digit number';
      hasErrors = true;
    }

    if (!contactDetails.email.trim()) {
      errors.contactDetails.email = 'Email is required';
      hasErrors = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactDetails.email.trim())) {
      errors.contactDetails.email = 'Please enter a valid email address';
      hasErrors = true;
    }

    // Validate passenger details
    const validatePassenger = (passenger, index, category) => {
      if (!passenger.title) {
        errors[category][index].title = 'Title is required';
        hasErrors = true;
      }

      if (!passenger.firstName.trim()) {
        errors[category][index].firstName = 'First name is required';
        hasErrors = true;
      }

      if (!passenger.lastName.trim()) {
        errors[category][index].lastName = 'Last name is required';
        hasErrors = true;
      }

      if ((category === 'children' || category === 'infants') && !passenger.dob) {
        errors[category][index].dob = 'Date of birth is required';
        hasErrors = true;
      }

      if (!passenger.nationality.trim()) {
        errors[category][index].nationality = 'Nationality is required';
        hasErrors = true;
      }

      if (!passenger.passportNumber.trim()) {
        errors[category][index].passportNumber = 'Passport number is required';
        hasErrors = true;
      }

      if (!passenger.passportIssueCountry.trim()) {
        errors[category][index].passportIssueCountry = 'Issue country is required';
        hasErrors = true;
      }

      if (!passenger.passportExpiry) {
        errors[category][index].passportExpiry = 'Expiry date is required';
        hasErrors = true;
      } else {
        // Validate passport is not expired
        const today = new Date();
        const expiryDate = new Date(passenger.passportExpiry);
        if (expiryDate <= today) {
          errors[category][index].passportExpiry = 'Passport has expired';
          hasErrors = true;
        }
      }
    };

    passengerDetails.adults.forEach((passenger, index) => {
      validatePassenger(passenger, index, 'adults');
    });

    passengerDetails.children.forEach((passenger, index) => {
      validatePassenger(passenger, index, 'children');
    });

    passengerDetails.infants.forEach((passenger, index) => {
      validatePassenger(passenger, index, 'infants');
    });

    setValidationErrors(errors);
    return !hasErrors;
  };

  const handleContinue = () => {
    if (validateForm()) {
      setCurrentStep('preview');
    }
  };

  const handleEditForm = () => {
    setCurrentStep('form');
  };

  const handleSubmit = async () => {
    // Prepare the payload with your contact and passenger details plus flight info
    // Calculate the total number of seats booked (excluding infants)
    const totalSeatsBooked = passengers.adult + passengers.child;
    const payload = {
      contactDetails,
      passengerDetails,
      flightInfo,
      seatsBooked: totalSeatsBooked,
      ticket_id: flightInfo.ticket_id
    };

    try {
      setSubmitting(true);
      const SERVER_URL = process.env.REACT_APP_SERVER_URL;  // Get the value from .env
      const response = await fetch(`${SERVER_URL}/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`Server Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Booking saved successfully:', data);

      alert("Booking submitted successfully!");

      // Reset form and navigate to booking screen
      setPassengerDetails(createInitialPassengerDetails());
      setContactDetails({ countryCode: '+91', mobile: '', email: '' });

      // Navigate back to booking screen
      if (typeof navigateToBooking === 'function') {
        navigateToBooking();
      }

    } catch (error) {
      console.error('Error occurred while submitting booking:', error);
      alert("Error submitting booking. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  const renderForm = () => (
    <div>
      {/* Flight Information */}
      <FlightInformation flightInfo={flightInfo} />
      
      {/* Price Summary */}
      <PricingSummary flightInfo={flightInfo} />

      {/* Contact Information */}
      <div style={{ marginBottom: '30px', backgroundColor: '#f8f8f8', padding: '20px', borderRadius: '8px' }}>
        <h3 style={{ marginBottom: '15px' }}>Contact Information</h3>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '15px' }}>
          <div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <select
                value={contactDetails.countryCode}
                onChange={(e) => updateContactDetail('countryCode', e.target.value)}
                style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ddd', width: '100px' }}
              >
                {countryCodes.map(code => <option key={code} value={code}>{code}</option>)}
              </select>
              <input
                type="tel"
                placeholder="Mobile Number"
                value={contactDetails.mobile}
                onChange={(e) => updateContactDetail('mobile', e.target.value)}
                style={{
                  padding: '8px',
                  borderRadius: '4px',
                  border: validationErrors.contactDetails?.mobile ? '1px solid #dc3545' : '1px solid #ddd',
                  flex: 1
                }}
              />
            </div>
            {validationErrors.contactDetails?.mobile && (
              <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
                {validationErrors.contactDetails.mobile}
              </div>
            )}
          </div>
          <div>
            <input
              type="email"
              placeholder="Email Address"
              value={contactDetails.email}
              onChange={(e) => updateContactDetail('email', e.target.value)}
              style={{
                padding: '8px',
                borderRadius: '4px',
                border: validationErrors.contactDetails?.email ? '1px solid #dc3545' : '1px solid #ddd',
                width: '100%'
              }}
            />
            {validationErrors.contactDetails?.email && (
              <div style={{ color: '#dc3545', fontSize: '0.8em', marginTop: '3px' }}>
                {validationErrors.contactDetails.email}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '10px', color: '#666' }}>
        Please make sure you enter the Name as per your Government photo id.
      </div>
      {/* Passenger Forms */}
      {passengers.adult > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '15px' }}>
            Adult x {passengers.adult}
          </h3>
          {passengerDetails.adults.map((data, index) => (
            <PassengerForm
              key={`adult-${index}`}
              type="Adult"
              index={index}
              data={data}
              category="adults"
              updatePassengerDetail={updatePassengerDetail}
              titleOptions={titleOptions}
              validationErrors={validationErrors}
            />
          ))}
        </div>
      )}
      {passengers.child > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '15px' }}>
            Child x {passengers.child}
          </h3>
          {passengerDetails.children.map((data, index) => (
            <PassengerForm
              key={`child-${index}`}
              type="Child"
              index={index}
              data={data}
              category="children"
              updatePassengerDetail={updatePassengerDetail}
              titleOptions={titleOptions}
              validationErrors={validationErrors}
            />
          ))}
        </div>
      )}
      {passengers.infant > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '15px' }}>
            Infant x {passengers.infant}
          </h3>
          {passengerDetails.infants.map((data, index) => (
            <PassengerForm
              key={`infant-${index}`}
              type="Infant"
              index={index}
              data={data}
              category="infants"
              updatePassengerDetail={updatePassengerDetail}
              titleOptions={titleOptions}
              validationErrors={validationErrors}
              style={{ textTransform: "uppercase" }}
            />
          ))}
        </div>
      )}
      <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
        <button
          onClick={onBack}
          style={{ padding: '10px 20px', borderRadius: '4px', border: '1px solid #ddd', background: 'white', cursor: 'pointer' }}
        >
          Back
        </button>
        <button
          onClick={handleContinue}
          style={{ padding: '10px 20px', borderRadius: '4px', border: 'none', backgroundColor: '#28a745', color: 'white', cursor: 'pointer' }}
        >
          Review Details
        </button>
      </div>
    </div>
  );

  const renderPreview = () => (
    <div style={{ padding: '15px' }}>
      <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '25px' }}>
        Review Traveller Details
      </h2>

      {/* Flight Information */}
      <FlightInformation flightInfo={flightInfo} />
      
      {/* Price Summary */}
      <PricingSummary flightInfo={flightInfo} />

      <div style={{ marginBottom: '30px', backgroundColor: '#f8f8f8', padding: '20px', borderRadius: '8px' }}>
        <h3 style={{ fontWeight: 'bold', marginBottom: '15px' }}>Contact Information</h3>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '15px' }}>
          <div>
            <span style={{ color: '#666', fontSize: '0.9em' }}>Mobile:</span>
            <div>{contactDetails.countryCode} {contactDetails.mobile}</div>
          </div>
          <div>
            <span style={{ color: '#666', fontSize: '0.9em' }}>Email:</span>
            <div>{contactDetails.email}</div>
          </div>
        </div>
      </div>
      {passengers.adult > 0 && (
        <div style={{ marginBottom: '30px' }}>
          <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '15px' }}>
            Adult Passengers
          </h3>
          {passengerDetails.adults.map((data, index) => (
            <PassengerSummary
              key={`adult-summary-${index}`}
              type="Adult"
              index={index}
              data={data}
              formatDate={formatDate}
            />
          ))}
        </div>
      )}
      {passengers.child > 0 && (
        <div style={{ marginBottom: '30px' }}>
          <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '15px' }}>
            Child Passengers
          </h3>
          {passengerDetails.children.map((data, index) => (
            <PassengerSummary
            key={`child-summary-${index}`}
              type="Child"
              index={index}
              data={data}
              formatDate={formatDate}
            />
          ))}
        </div>
      )}
      {passengers.infant > 0 && (
        <div style={{ marginBottom: '30px' }}>
          <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '15px' }}>
            Infant Passengers
          </h3>
          {passengerDetails.infants.map((data, index) => (
            <PassengerSummary
              key={`infant-summary-${index}`}
              type="Infant"
              index={index}
              data={data}
              formatDate={formatDate}
            />
          ))}
        </div>
      )}
      <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
        <button
          onClick={handleEditForm}
          style={{ padding: '10px 20px', borderRadius: '4px', border: '1px solid #ddd', background: 'white', cursor: 'pointer' }}
        >
          Edit Details
        </button>
        <button
          onClick={handleSubmit}
          disabled={submitting}
          style={{
            padding: '10px 20px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#28a745',
            color: 'white',
            cursor: submitting ? 'not-allowed' : 'pointer',
            opacity: submitting ? 0.7 : 1
          }}
        >
          {submitting ? 'Processing...' : 'Confirm & Continue'}
        </button>
      </div>
    </div>
  );

  return (
    <div style={{ padding: '20px', maxWidth: '900px', margin: '0 auto' }}>
      <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '20px' }}>
        {currentStep === 'form' ? 'Traveller Details' : 'Review Traveller Details'}
      </h2>
      {currentStep === 'form' ? renderForm() : renderPreview()}
    </div>
  );
};

export default PassengerDetailsForm;