import React, { useState } from "react";
import Select from "react-select";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AirportSelect = ({ label, value, onChange }) => {
  const [options, setOptions] = useState([]);

  // Fetch airport data dynamically
  const fetchAirports = async (inputValue) => {
    if (!inputValue || inputValue.length < 2) {
      setOptions([]); // Reset if input is empty or too short
      return;
    }

    try {
      console.log(`Fetching airports for query: ${inputValue}`);
      const response = await fetch(`${SERVER_URL}/search_airports?query=${inputValue}`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.json();
      console.log("Fetched data:", data);

      if (data && Array.isArray(data.airports)) {
        setOptions(
          data.airports.map((airport) => ({
            value: airport.iata_code,
            label: `${airport.iata_code} - ${airport.name}`,
          }))
        );
      } else {
        console.error("Invalid API response format:", data);
        setOptions([]);
      }
    } catch (error) {
      console.error("Error fetching airports:", error);
      setOptions([]);
    }
  };

  return (
    <div>
      <label>{label}</label>
      <Select
        options={options}
        onInputChange={(value, { action }) => {
          if (action === "input-change") fetchAirports(value);
        }}
        onChange={onChange}
        value={options.find((opt) => opt.value === value) || null} // Keep selected value
        placeholder="Search airport (e.g., CCJ)"
        isClearable
      />
    </div>
  );
};

export default AirportSelect;
