import React, { useEffect, useState } from "react";
import PassengerDetailsForm from './PassengerDetailsForm';
import { FaPlane, FaCalendarAlt, FaRupeeSign, FaChair, FaUser, FaChild, FaBaby, 
         FaTimes, FaArrowRight, FaLongArrowAltRight, FaClock, FaTicketAlt } from 'react-icons/fa';
import './FlightSearchResults.css';

const FlightSearchResults = ({ searchParams }) => {
    const [flights, setFlights] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPassengerCount, setShowPassengerCount] = useState(true);
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [passengers, setPassengers] = useState({
        adult: 1,
        child: 0,
        infant: 0
    });
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        const fetchFlights = async () => {
            try {
                setLoading(true);
                const response = await fetch(
                    `${SERVER_URL}/search_results?origin=${searchParams.origin}&destination=${searchParams.destination}&date=${searchParams.date}`
                );
                if (!response.ok) throw new Error("Failed to fetch flights");
                const data = await response.json();
                setFlights(data);
            } catch (error) {
                console.error("Error fetching flights:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchFlights();
    }, [searchParams, SERVER_URL]);

    const handlePassengerChange = (type, operation) => {
        setPassengers(prev => ({
            ...prev,
            [type]: operation === 'add'
                ? prev[type] + 1
                : type === 'adult'
                    ? Math.max(1, prev[type] - 1)
                    : Math.max(0, prev[type] - 1)
        }));
    };

    const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowPassengerCount(true);
    setPassengers({ adult: 1, child: 0, infant: 0 }); // Reset passenger counts
    setSelectedFlight(null); // Clear selected flight
};

    const prepareFlightInfo = (flight) => ({
        ticket_id: flight.ticket_id,
        airline: flight.airline,
        flightNumber: flight.flightnumber,
        date: searchParams.date,
        from: flight.origin,
        to: flight.destination,
        departureTime: flight.departure_time,
        arrivalTime: flight.arrival_time
    });

    const formatTime = (timeString) => timeString;

    const calculateDuration = (depTime, arrTime) => {
        if (!depTime || !arrTime) return "";
        const [depHours, depMinutes] = depTime.split(":" ).map(Number);
        const [arrHours, arrMinutes] = arrTime.split(":" ).map(Number);
        const depDate = new Date();
        depDate.setHours(depHours, depMinutes, 0, 0);
        const arrDate = new Date();
        arrDate.setHours(arrHours, arrMinutes, 0, 0);
        if (arrDate < depDate) arrDate.setDate(arrDate.getDate() + 1);
        const diffMs = arrDate - depDate;
        const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
        return `${diffHours}h ${diffMinutes}m`;
    };

    const Modal = ({ isOpen, onClose, children }) => {
        if (!isOpen) return null;
        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button className="modal-close" onClick={onClose}><FaTimes /></button>
                    {children}
                </div>
            </div>
        );
    };

    const PassengerCounter = ({ type, value, icon }) => (
    <div className="passenger-counter">
        <div className="passenger-type">
            {icon}
            <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
            {type === 'adult' && <span className="type-note">(12+ yrs)</span>}
            {type === 'child' && <span className="type-note">(2-11 yrs)</span>}
            {type === 'infant' && <span className="type-note">(below 2 yrs)</span>}
        </div>
        <div className="counter-controls">
            <button
                onClick={() => handlePassengerChange(type, 'subtract')}
                disabled={type === 'adult' ? value <= 1 : value <= 0}
                className={`counter-btn ${(type === 'adult' ? value <= 1 : value <= 0) ? 'disabled' : ''}`}
            >-</button>
            <span className="counter-value">{value}</span>
            <button
                onClick={() => handlePassengerChange(type, 'add')}
                disabled={
                    type === 'adult'
                        ? value >= (selectedFlight?.seat_available || 0)
                        : type === 'child'
                            ? value >= (selectedFlight?.seat_available || 0) - passengers.adult
                            : false
                }
                className={`counter-btn ${(type === 'adult'
                    ? value >= (selectedFlight?.seat_available || 0)
                    : type === 'child'
                        ? value >= (selectedFlight?.seat_available || 0) - passengers.adult
                        : false) ? 'disabled' : ''}`}
            >+</button>
        </div>
    </div>
);

const PassengerSection = React.memo(() => (
    <>
        <PassengerCounter type="adult" value={passengers.adult} icon={<FaUser className="passenger-icon" />} />
        <PassengerCounter type="child" value={passengers.child} icon={<FaChild className="passenger-icon" />} />
        <PassengerCounter type="infant" value={passengers.infant} icon={<FaBaby className="passenger-icon" />} />
    </>
));

    return (
        <div className="flight-results-container">
            <div className="results-header">
                <h2><FaPlane className="icon-spacing" /> Available Flights</h2>
                <div className="search-summary">
                    <span><strong>{searchParams.origin}</strong> <FaLongArrowAltRight className="icon-spacing" /> <strong>{searchParams.destination}</strong></span>
                    <span className="date-display"><FaCalendarAlt className="icon-spacing" /> {searchParams.date}</span>
                    <span className="flight-count">{flights.length} flights found</span>
                </div>
            </div>

            {loading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Searching for the best flights...</p>
                </div>
            ) : flights.length > 0 ? (
                <div className="flights-grid">
                    {flights.map((flight, index) => (
                        <div key={index} className="flight-card">
                            <div className="flight-card-header">
                                <span className="airline-name">{flight.airline}</span>
                                <span className="flight-number">{flight.flightNumber}</span>
                            </div>
                            <div className="flight-route">
                                <div className="departure">
                                    <div className="time">{formatTime(flight.departure_time)}</div>
                                    <div className="location">{flight.origin}</div>
                                </div>
                                <div className="flight-path">
                                    <div className="route-line"></div>
                                    <FaPlane className="plane-icon" />
                                    <div className="duration">{calculateDuration(flight.departure_time, flight.arrival_time)}</div>
                                </div>
                                <div className="arrival">
                                    <div className="time">{formatTime(flight.arrival_time)}</div>
                                    <div className="location">{flight.destination}</div>
                                </div>
                            </div>
                            <div className="flight-details">
                                <div className="detail-item">
                                    <FaRupeeSign className="detail-icon" />
                                    <span className="price">{flight.price}</span>
                                </div>
                                <div className="detail-item">
                                    <FaChair className="detail-icon" />
                                    <span>{flight.seat_available} seats available</span>
                                </div>
                            </div>
                            <button
                                className="book-button"
                                onClick={() => {
                                    setSelectedFlight(flight);
                                    setIsModalOpen(true);
                                    setShowPassengerCount(true);
                                }}
                            >
                                Book Now
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="no-results">
                    <div className="no-results-icon">🔎</div>
                    <h3>No flights found</h3>
                    <p>Try adjusting your search criteria or checking different dates.</p>
                </div>
            )}

            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                {showPassengerCount ? (
                    <div className="passenger-selection">
                        <div className="modal-header">
                            <h2><FaTicketAlt className="header-icon" /> Select Passengers</h2>
                        </div>
                        <div className="modal-body">
                            <div className="passenger-total">
                                <div className="total-count">
                                    <FaUser className="count-icon" />
                                    <div>
                                        <strong>Total Passengers:</strong> {passengers.adult + passengers.child}
                                        {passengers.infant > 0 && <span> (+ {passengers.infant} infant{passengers.infant > 1 ? 's' : ''})</span>}
                                    </div>
                                </div>
                                <div className="total-price">
                                    <FaRupeeSign className="rupee-icon" />
                                    <div>
                                        <strong>Total:</strong> {
    selectedFlight
        ? (Number(selectedFlight.price) * (passengers.adult + passengers.child)) +
          (Number(selectedFlight.infant_price || 0) * passengers.infant)
        : 0
}
                                    </div>
                                </div>
                            </div>
                            <div className="passenger-selection-title">
                                <h3>Choose number of passengers</h3>
                            </div>
                            <div className="passenger-counters">
    <PassengerSection />
</div>
                            <button className="continue-button" onClick={() => setShowPassengerCount(false)}>
                                Continue to Passenger Details <FaArrowRight className="continue-icon" />
                            </button>
                            <div className="modal-note">
                                <p>You'll be able to enter passenger details in the next step.</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <PassengerDetailsForm
                        passengers={passengers}
                        flightInfo={selectedFlight ? prepareFlightInfo(selectedFlight) : null}
                        onSubmit={(details) => {
                            console.log("Passenger details:", details);
                            console.log("Selected flight:", selectedFlight);
                            handleCloseModal();
                        }}
                        onBack={() => setShowPassengerCount(true)}
                        navigateToBooking={handleCloseModal}
                    />
                )}
            </Modal>
        </div>
    );
};

export default FlightSearchResults;
