import React, { useState, useEffect } from 'react';
import './AddTicket.css';
import { Container, Row, Col, Table, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AirportSelect from './AirportSelect';
import flysaharaLogo from './assets/flysahara_logo.png';

const AddTicket = () => {
    const [formData, setFormData] = useState({
        id: null, airline: '', flightNumber: '', origin: '', destination: '', date: '',
        departure_time: '', arrival_time: '', price: '', infant_price: '',
        seat_available: '', baggage: '' 
    });
    const [existingTickets, setExistingTickets] = useState([]);
    const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
    const navigate = useNavigate();
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        if (formData.origin && formData.destination && formData.date) {
            fetchExistingTickets();
        }
    }, [formData.origin, formData.destination, formData.date]);

    const fetchExistingTickets = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/search_results?origin=${formData.origin}&destination=${formData.destination}&date=${formData.date}`);
            if (!response.ok) throw new Error("Failed to fetch");
            const data = await response.json();
            setExistingTickets(data);
        } catch (error) {
            console.error("Error fetching tickets:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${SERVER_URL}/add`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (!response.ok) throw new Error(result.error || "Submission failed");

            setAlert({ show: true, message: result.message, variant: "success" });

            setFormData({
                id: null, airline: '', flightNumber: '', origin: '', destination: '', date: '',
                departure_time: '', arrival_time: '', price: '', infant_price: '',
                seat_available: '', baggage: ''
            });

            fetchExistingTickets();
        } catch (error) {
            console.error("Error submitting form:", error);
            setAlert({ show: true, message: error.message, variant: "danger" });
        }
    };

    const handleEdit = (ticket) => {
        setFormData(ticket);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this ticket?")) return;
        try {
            const response = await fetch(`${SERVER_URL}/delete/${id}`, { method: "DELETE" });
            if (!response.ok) throw new Error("Delete failed");
            setAlert({ show: true, message: "Ticket deleted", variant: "warning" });
            fetchExistingTickets();
        } catch (error) {
            console.error("Delete error:", error);
        }
    };

    const goBack = () => {
        navigate('/');
    };

    const clearForm = () => {
        setFormData({
            id: null, airline: '', flightNumber: '', origin: '', destination: '', date: '',
            departure_time: '', arrival_time: '', price: '', infant_price: '',
            seat_available: '', baggage: ''
        });
    };

    return (
        <div className="admin-login-page" style={{
            background: 'linear-gradient(90deg, rgba(226,182,154,0.8) 0%, rgba(192,57,82,0.8) 100%)',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'start',
            padding: '30px 0'
        }}>
            <Container>
                <Row className="justify-content-center">
                    <Col md={10} className="p-4 shadow-sm bg-white rounded">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <img src={flysaharaLogo} alt="FlySahara Logo" style={{ height: '40px' }} />
                            <h4 className="text-center mb-0">Admin - Manage Tickets</h4>
                            <Button variant="outline-secondary" onClick={goBack}>Back</Button>
                        </div>

                        {alert.show && (
                            <Alert variant={alert.variant} onClose={() => setAlert({ show: false })} dismissible>
                                {alert.message}
                            </Alert>
                        )}

                        <Row>
                            <Col md={5} className="form-container">
                                <form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <label>Airline</label>
                                            <input type="text" className="form-control" name="airline" value={formData.airline} onChange={handleInputChange} style={{ textTransform: "uppercase" }} required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <label>Flight Number</label>
                                            <input type="text" className="form-control" name="flightNumber" value={formData.flightNumber} onChange={handleInputChange} required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <AirportSelect label="Origin" value={formData.origin} onChange={(selectedOption) => setFormData(prev => ({ ...prev, origin: selectedOption?.value || '' }))} />
                                        </Col>
                                        <Col md={6}>
                                            <AirportSelect label="Destination" value={formData.destination} onChange={(selectedOption) => setFormData(prev => ({ ...prev, destination: selectedOption?.value || '' }))} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <label>Date</label>
                                            <input type="date" className="form-control" name="date" value={formData.date} onChange={handleInputChange} required />
                                        </Col>
                                        <Col md={6}>
                                            <label>Departure Time</label>
                                            <input type="time" className="form-control" name="departure_time" value={formData.departure_time} onChange={handleInputChange} required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <label>Arrival Time</label>
                                            <input type="time" className="form-control" name="arrival_time" value={formData.arrival_time} onChange={handleInputChange} required />
                                        </Col>
                                        <Col md={6}>
                                            <label>Price (₹)</label>
                                            <input type="number" className="form-control" name="price" value={formData.price} onChange={handleInputChange} required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <label>Infant Price (₹)</label> 
                                            <input type="number" className="form-control" name="infant_price" value={formData.infant_price} onChange={handleInputChange} required />
                                        </Col>
                                        <Col md={6}>
                                            <label>No of Seats</label>
                                            <input type="number" className="form-control" name="seat_available" value={formData.seat_available} onChange={handleInputChange} required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <label>Baggage (optional)</label>
                                            <input type="text" className="form-control" name="baggage" value={formData.baggage} onChange={handleInputChange} />
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-danger px-4">
                                            {formData.id ? "Update Ticket" : "Add Ticket"}
                                        </button>
                                        <Button variant="outline-secondary" className="ms-2" onClick={clearForm}>
                                            Clear
                                        </Button>
                                    </div>
                                </form>
                            </Col>

                            <Col md={7} className="ticket-list ps-md-5 pt-4 pt-md-0">
                                <h5 className="mb-3">Existing Tickets - {formData.date}</h5>
                                <Table striped bordered hover responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th>Airline</th>
                                            <th>Flight</th>
                                            <th>Price</th>
                                            <th>Seats</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {existingTickets.map(ticket => (
                                            <tr key={ticket.id}>
                                                <td>{ticket.airline}</td>
                                                <td>{ticket.flightNumber}</td>
                                                <td>₹{ticket.price}</td>
                                                <td>{ticket.seat_available}</td>
                                                <td>
                                                    <Button size="sm" variant="warning" className="me-2" onClick={() => handleEdit(ticket)}>Edit</Button>
                                                    <Button size="sm" variant="danger" onClick={() => handleDelete(ticket.id)}>Delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddTicket;
