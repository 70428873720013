import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FlightSearchResults from "./FlightSearchResults";
import AirportSelect from "./AirportSelect";
import { 
  FaFacebookF, 
  FaInstagram, 
  FaTwitter, 
  FaCalendarAlt, 
  FaMapMarkerAlt,
  FaPlane,
  FaPassport,
  FaHotel,
  FaShieldAlt,
  FaUmbrellaBeach,
  FaUserShield
} from "react-icons/fa";
import flysaharaLogo from './assets/flysahara_logo.png';
import tajImage from './assets/tajmahal.jpg';
import './TicketBooking1.css';

const TicketBooking = () => {
    const [searchParams, setSearchParams] = useState({ 
        origin: "", 
        destination: "", 
        date: "" 
    });
    const [showResults, setShowResults] = useState(false);
    const navigate = useNavigate();

    const handleOriginSelect = (value) => {
        setSearchParams(prev => ({ ...prev, origin: value }));
    };

    const handleDestinationSelect = (value) => {
        setSearchParams(prev => ({ ...prev, destination: value }));
    };

    const handleSearch = () => {
        setShowResults(true);
    };

    const goToAdminLogin = () => {
        navigate('/admin-login');
    };

    return (
        <div className="booking-container">
            {/* Hero section with gradient overlay */}
            <div className="booking-hero" style={{ backgroundImage: `url(${tajImage})` }}>
                <div className="gradient-overlay">
                    
                    {/* Top Bar with Logo and Admin Login */}
                    <div className="d-flex justify-content-between align-items-center p-3">
                        <div className="logo-container ms-md-5 ms-3 mt-3">
                            <img src={flysaharaLogo} alt="FlySahara Logo" />
                        </div>
                        
                        {/* Desktop Admin Login Button */}
                        <button onClick={goToAdminLogin} className="btn admin-login-btn me-md-5 me-3 d-none d-md-block">
                            Admin Login
                        </button>
                        
                        {/* Mobile Admin Login Button (icon only) */}
                        <button onClick={goToAdminLogin} className="btn admin-login-mobile-btn me-3 d-md-none">
                            <FaUserShield size={18} />
                        </button>
                    </div>

                    {/* Social Media Icons */}
                    <div className="social-icons">
                        <div className="d-flex flex-column gap-2">
                            <a href="#" className="btn btn-primary p-2"><FaFacebookF /></a>
                            <a href="#" className="btn btn-danger p-2"><FaInstagram /></a>
                            <a href="#" className="btn btn-info p-2"><FaTwitter /></a>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="hero-content text-white">
                        <h1>FIND YOUR SPECIAL FARE</h1>
                        <p>SEARCH BEST PRICES ACROSS ALL MAJOR AIRLINES</p>
                    </div>

                    {/* Search Form */}
                    <div className="search-form-container">
                        <div className="search-box">
                            <div className="row g-3 align-items-center">
                                <div className="col-md-4">
                                    <label className="form-label text-white mb-2">Leaving From</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <FaMapMarkerAlt />
                                        </span>
                                        <AirportSelect
                                            className="form-select"
                                            placeholder="Search Airport Name"
                                            value={searchParams.origin}
                                            onSelect={handleOriginSelect}
                                            onChange={(selectedOption) => setSearchParams(prev => ({ ...prev, origin: selectedOption?.value || '' }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label text-white mb-2">Going To</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <FaMapMarkerAlt />
                                        </span>
                                        <AirportSelect
                                            className="form-select"
                                            placeholder="Search Airport Name"
                                            value={searchParams.destination}
                                            onSelect={handleDestinationSelect}
                                            onChange={(selectedOption) => setSearchParams(prev => ({ ...prev, destination: selectedOption?.value || '' }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label text-white mb-2">Date</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <FaCalendarAlt />
                                        </span>
                                        <input
                                            type="date"
                                            value={searchParams.date}
                                            onChange={(e) => setSearchParams(prev => ({ ...prev, date: e.target.value }))}
                                            className="form-control"
                                            placeholder="DD/MM/YYYY"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label text-white mb-2">&nbsp;</label>
                                    <button 
                                        className="btn search-button w-100" 
                                        onClick={handleSearch}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Show search results if available */}
            {showResults && <FlightSearchResults searchParams={searchParams} />}

            {/* Enhanced Service Icons Section */}
            <div className="services py-3">
                <div className="container">
                    <div className="service-strip">
                        <div className="service-item">
                            <FaPlane size={18} className="service-icon" />
                            <span>FLIGHTS</span>
                        </div>
                        <div className="service-divider"></div>
                        <div className="service-item">
                            <FaPassport size={18} className="service-icon" />
                            <span>VISAS</span>
                        </div>
                        <div className="service-divider"></div>
                        <div className="service-item">
                            <FaHotel size={18} className="service-icon" />
                            <span>HOTELS</span>
                        </div>
                        <div className="service-divider"></div>
                        <div className="service-item">
                            <FaShieldAlt size={18} className="service-icon" />
                            <span>INSURANCE</span>
                        </div>
                        <div className="service-divider"></div>
                        <div className="service-item">
                            <FaUmbrellaBeach size={18} className="service-icon" />
                            <span>HOLIDAYS</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer>
                <div>Copyright © 2025 FlySahara Private Limited. All rights reserved</div>
            </footer>
        </div>
    );
};

export default TicketBooking;