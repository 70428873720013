import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FaArrowLeft, FaUser, FaLock } from 'react-icons/fa';
import flysaharaLogo from './assets/flysahara_logo.png';
import './AdminLogin.css';

const AdminLogin = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            // Example validation - replace with your actual authentication logic
            const SERVER_URL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${SERVER_URL}/admin-login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials)
            });

            if (!response.ok) {
                throw new Error('Invalid credentials');
            }

            // If login successful, navigate to admin dashboard or add ticket page
            navigate('/add-ticket');
        } catch (error) {
            setError('Invalid username or password. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const goBack = () => {
        navigate('/');
    };

    return (
        <div className="admin-login-page" style={{ 
            background: 'linear-gradient(90deg, rgba(226,182,154,0.8) 0%, rgba(192,57,82,0.8) 100%)',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center'
        }}>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} lg={5} className="login-form-container">
                        <div className="text-center mb-4">
                            <img src={flysaharaLogo} alt="FlySahara Logo" className="admin-logo mb-4" />
                            <h2 className="text-white">Admin Login</h2>
                        </div>
                        
                        <div className="login-card p-4 shadow-sm">
                            {error && <Alert variant="danger">{error}</Alert>}
                            
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Username</Form.Label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <FaUser />
                                        </span>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            value={credentials.username}
                                            onChange={handleInputChange}
                                            placeholder="Enter your username"
                                            required
                                        />
                                    </div>
                                </Form.Group>
                                
                                <Form.Group className="mb-4">
                                    <Form.Label>Password</Form.Label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <FaLock />
                                        </span>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={credentials.password}
                                            onChange={handleInputChange}
                                            placeholder="Enter your password"
                                            required
                                        />
                                    </div>
                                </Form.Group>
                                
                                <div className="d-grid gap-2">
                                    <Button variant="danger" type="submit" disabled={loading} style={{ backgroundColor: '#C03952', borderColor: '#C03952' }}>
                                        {loading ? 'Logging in...' : 'Login'}
                                    </Button>
                                    
                                    <Button variant="outline-secondary" onClick={goBack} className="mt-2">
                                        <FaArrowLeft className="me-2" /> Back to Homepage
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AdminLogin;