import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';  // Import Admin Login component
import TicketBooking from './components/TicketBooking';  // Import Ticket Booking component
import AgentBooking from './components/AgentBooking';  // Import Agent Booking component
import Navbar from './components/navbaravbar';  // Optional: Navbar for easy navigation
import AddTicket from './components/addticket';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TicketBooking />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/add-ticket" element={<AddTicket />} />
      </Routes>
    </Router>
  );
}
export default App;
// In the above code snippet, we have created a simple React application with three components: AdminLogin, TicketBooking, and AgentBooking. We have also created a NavigationBar component to navigate between these components.